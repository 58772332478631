$(document).ready(function () {
  image_responsive();
  fullPageInit();
  scrollRevealInit();
  menuToggle();
  fancyboxSetting();
  setJobHeight();
  $(window).resize(function () {
    image_responsive();
    setJobHeight();
  });

  if ($(window).width() < 1025) {
    $(".back-to-top").before($(".language-wrap-fixed"));
  }

  let zero = 0;

  $(window).scroll(function () {
    var windowScrollTop = $(window).scrollTop();

    $("body:not('.homepage') header").toggleClass(
      "hide",
      windowScrollTop > zero
    );
    zero = $(window).scrollTop();

    if (windowScrollTop > 0) {
      $("header").addClass("is-scrolled");
      setTimeout(() => {
        $(".product-detail-page:not('.fp-viewing-0') header").removeClass(
          "is-scrolled"
        );
      }, 300);
    } else {
      $("header").removeClass("is-scrolled");
    }

    var yPos = $(window).scrollTop();
    if (yPos >= $(document).height() - $(window).height()) {
      $(".load-more").trigger("click");
    }
  });

  $(document).on("click", ".load-more", function (e) {
    e.preventDefault();
    let url = $(this).attr("href");
    let obj = $(this);
    $.ajax({
      url: url,
      success: function (response) {
        var html = $(response).find(".projects-list .uk-grid").html();
        var loadMoreBtn = $(response).find(".load-more");
        $(".projects-list .uk-grid").append(html);
        $(".projects-list .uk-grid").after(loadMoreBtn);
        obj.remove();
      },
    });
  });

  $(document).on("click", ".btn-view-more", function (e) {
    e.preventDefault();
    let url = $(this).attr("href");
    let obj = $(this);
    console.log(url);
    $.ajax({
      url: url,
      success: function (response) {
        var newsList = $(response).find(".list-news-top").html();
        var loadMoreBtn = $(response).find(".btn-view-more");

        var newsItemBig = $(newsList).find(".news-item-big");
        $(".list-news-top").append(newsItemBig);
        $(newsItemBig).wrap("<div class='uk-width-1-3@m'></div>");
        $(newsItemBig).removeClass("news-item-big").addClass("news-item-small");
        var newsItemSide = $(newsList).find(".news-item-side");
        $(".list-news-top").append(newsItemSide);
        $(newsItemSide).wrap("<div class='uk-width-1-3@m'></div>");
        var newsItemSmall = $(newsList).find(".news-item-col");
        console.log(newsItemSmall);
        $(".list-news-top").append(newsItemSmall);

        $(".list-news-top").after(loadMoreBtn);
        obj.remove();
      },
    });
  });

  // var yPos = $('.footer').scrollTop();
  $(document).scroll(function () {
    $(".load-more").on("inview", function (event, isInView) {
      if (isInView) {
        $(".load-more").trigger("click");
        $(".load-more").remove();
      } else {
        // element has gone out of viewport
      }
    });
  });

  $(".filter-item-custom-field").each(function () {
    var textActive = $(this).find(".filter-active").text();
    if (textActive != "") {
      $(this).find("a.active > span.text").text(textActive);
    }
  });

  $(".search a").click(function () {
    $(this).toggleClass("show");
  });

  $(".news-category").appendTo(".news-sidebar").removeClass("uk-hidden");
  $(".news-feature").appendTo(".news-sidebar").removeClass("uk-hidden");
  $(".news-banner").appendTo(".news-sidebar").removeClass("uk-hidden");
});

function setLeftSimilarProject() {
  var left_dis = $(".project-detail-similar .uk-container h2").offset().left;
  $(".project-detail-similar .item .title").css({
    left: left_dis + "px",
  });
}

function setJobHeight() {
  if ($(window).width() > 768) {
    $(".job-list .job-item").each(function () {
      var height = $(this).find(".job-title").outerHeight();
      $(this).find(".job-desc").height(height);
    });
  }
}

function fancyboxSetting() {
  $("[data-trigger='gallery']").on("click", function (e) {
    e.preventDefault();
    var triggerFancybox = $(this).attr("data-trigger");
    $.fancybox.open($("[data-fancybox=" + triggerFancybox + "]"), {
      thumbs: {
        autoStart: true,
        axis: "x",
      },
    });
  });

  $(".project-detail-title a[data-fancybox]").fancybox({
    thumbs: {
      autoStart: true,
      axis: "x",
    },
  });
}

function scrollRevealInit() {
  var slideUp = {
    distance: "150%",
    origin: "bottom",
    opacity: 0,
    duration: 2500,
    reset: true,
    easing: "ease",
  };

  var slideUpNoReset = {
    distance: "150%",
    origin: "bottom",
    opacity: 0,
    duration: 2500,
    easing: "ease",
  };

  var slideUpFastNoReset = {
    distance: "151%",
    origin: "bottom",
    opacity: 0,
    duration: 2500,
    easing: "ease",
  };

  ScrollReveal().reveal(".section-inner .content", slideUp);
  if ($(window).width() > 576 && $(window).scrollTop() == 0) {
    ScrollReveal().reveal(".homepage .logo", slideUpNoReset);
    ScrollReveal().reveal(".homepage .search", slideUpFastNoReset);
    ScrollReveal().reveal(
      ".homepage header .uk-navbar-container",
      slideUpNoReset
    );
  }
}

function fullPageInit() {
  $("#fp-container").fullpage({
    scrollingSpeed: 1500,
    scrollOverflow: true,
    scrollBar: true,
    css3: true,
    easingcss3: "cubic-bezier(0.46, 0.01, 0.17, 1)",
    navigation: true,
    navigationPosition: "right",
    autoScrolling: true,
    scrollingSpeed: 1200,
    recordHistory: false,
    loopBottom: true,
    // continuousVertical: true,
    afterRender: function () {
      var autoScroll = setInterval(function () {
        $.fn.fullpage.moveSectionDown();
      }, 8000);
      $(".menu-toggle").click(function () {
        clearInterval(autoScroll);
      });
      $(".st-overlay").click(function () {
        autoScroll = setInterval(function () {
          $.fn.fullpage.moveSectionDown();
        }, 8000);
      });
      $(".back-to-top").click(function () {
        clearInterval(autoScroll);
        setTimeout(() => {
          autoScroll = setInterval(function () {
            $.fn.fullpage.moveSectionDown();
          }, 8000);
        }, 1000);
      });
      fullpage_api.setAllowScrolling(false, "up");
    },
  });
  if ($(window).width() > 1025) {
    $("#fp-project-detail").fullpage({
      scrollingSpeed: 1500,
      scrollOverflow: false,
      scrollBar: true,
      css3: true,
      easingcss3: "cubic-bezier(0.46, 0.01, 0.17, 1)",
      autoScrolling: true,
      scrollingSpeed: 1200,
      recordHistory: false,
      afterLoad: function (origin, destination, direction) {
        $(".project-detail-similar .swiper-container").after($("footer"));
      },
      afterRender: function () {
        const similarProjectSlide = new Swiper(
          ".project-detail-similar .swiper-container",
          {
            slidesPerView: 3,
            slidesPerColumn: 2,
            slidesPerColumnFill: "row",
            navigation: {
              prevEl: ".project-detail-similar .swiper-prev",
              nextEl: ".project-detail-similar .swiper-next",
            },
            breakpoints: {
              768: {
                slidesPerView: 2,
              },
              576: {
                slidesPerView: 1,
              },
            },
          }
        );
      },
    });
  } else {
    const similarProjectSlide = new Swiper(
      ".project-detail-similar .swiper-container",
      {
        slidesPerView: 3,
        slidesPerColumn: 2,
        slidesPerColumnFill: "row",
        navigation: {
          prevEl: ".project-detail-similar .swiper-prev",
          nextEl: ".project-detail-similar .swiper-next",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          576: {
            slidesPerView: 1,
          },
        },
      }
    );
  }
  $(".mouse").click(function () {
    fullpage_api.moveTo(2);
  });
}

// document.addEventListener("contextmenu", event => event.preventDefault());

function image_responsive() {
  $(".section").each(function (i) {
    if ($(window).width() < 768) {
      var img_responsive = $(this).attr("data-responsive");
      $(this).css("background-image", "url(" + img_responsive + ")");
    } else {
      var img_desktop = $(this).attr("data-desktop");
      $(this).css("background-image", "url(" + img_desktop + ")");
    }
  });
}

function menuToggle() {
  let pusherHeight = $("#st-container .st-pusher").innerHeight();
  $(".menu-toggle").click(function () {
    let windowHeight = $(window).innerHeight();
    $("#st-container").addClass("st-menu-open");
    $("#st-container .st-pusher").innerHeight(windowHeight);
    $(".mobile-menu").toggleClass("open");
    $(".fp-right").fadeOut();
    $("body").addClass("uk-overflow-hidden");
  });
  $(".st-overlay").click(function () {
    $("#st-container").removeClass("st-menu-open");
    $("body").removeClass("uk-overflow-hidden");
    $(".fp-right").fadeIn();
    setTimeout(() => {
      $("#st-container .st-pusher").attr("style", "");
    }, 500);
    $(".mobile-menu").removeClass("open");
  });
  $("html, body").bind("mousewheel", function (e) {
    $("#st-container").removeClass("st-menu-open");
    $("body").removeClass("uk-overflow-hidden");
    $(".fp-right").fadeIn();
    setTimeout(() => {
      $("#st-container .st-pusher").attr("style", "");
    }, 500);
    $(".mobile-menu").removeClass("open");
  });
}

const menuMobileMapping = new MappingListener({
  selector: ".menu-wrapper",
  mobileWrapper: ".mobile-menu",
  mobileMethod: "appendTo",
  desktopWrapper: ".logo",
  desktopMethod: "insertAfter",
  breakpoint: 576,
}).watch();

const peopleSlide = new Swiper(".about-3 .swiper-container", {
  slidesPerView: 6,
  slidesPerColumn: 2,
  slidesPerColumnFill: "row",
  spaceBetween: 36,
  speed: 1700,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    prevEl: ".about-3 .swiper-prev",
    nextEl: ".about-3 .swiper-next",
  },
  breakpoints: {
    1025: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 3,
    },
    576: {
      spaceBetween: 18,
      slidesPerView: 2,
    },
  },
});

const awardSlide = new Swiper(".about-4 .swiper-container", {
  slidesPerView: 3,
  slidesPerColumn: 2,
  slidesPerColumnFill: "row",
  speed: 1700,
  autoplay: {
    delay: 3000,
  },
  navigation: {
    prevEl: ".about-4 .swiper-prev",
    nextEl: ".about-4 .swiper-next",
  },
  breakpoints: {
    1025: {
      slidesPerView: 2,
    },
    576: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
  },
});

const partnersSlide = new Swiper(".about-5 .swiper-container", {
  slidesPerView: 5,
  slidesPerColumn: 2,
  slidesPerColumnFill: "row",
  navigation: {
    prevEl: ".about-5 .swiper-prev",
    nextEl: ".about-5 .swiper-next",
  },
  spaceBetween: 30,
  speed: 1700,
  autoplay: {
    delay: 3000,
  },
  breakpoints: {
    1025: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
  },
});
